/*
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }

$breakpoint arguement choices:
- xxxs
- xxs
- xs
- sm
- md
- lg
- xl
- xxl

1em = 16px
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 62em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (min-width: 87.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  background-color: #FAF9F6; }

a {
  text-decoration: none; }

::selection {
  background-color: #8081b7;
  color: #FFF; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(4rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  color: #000; }

.j-heading-primary {
  font-size: 3.5rem;
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  animation-name: moveInLeft;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-timing-function: ease-out; }
  @media only screen and (max-width: 48em) {
    .j-heading-primary {
      font-size: 2.5rem; } }
  @media only screen and (max-width: 36em) {
    .j-heading-primary {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 22em) {
    .j-heading-primary {
      font-size: 1.8rem; } }

.j-heading-secondary-white {
  font-size: 3rem;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600; }
  @media only screen and (max-width: 36em) {
    .j-heading-secondary-white {
      font-size: 2rem; } }
  @media only screen and (max-width: 22em) {
    .j-heading-secondary-white {
      font-size: 1.7rem; } }

.j-heading-secondary-black {
  font-size: 3rem;
  text-transform: uppercase;
  color: #000;
  font-weight: 900; }
  @media only screen and (max-width: 36em) {
    .j-heading-secondary-black {
      font-size: 2rem; } }
  @media only screen and (max-width: 22em) {
    .j-heading-secondary-black {
      font-size: 1.7rem; } }

.j-heading-secondary-major {
  font-size: 3rem;
  color: #8081b7;
  font-weight: 900; }
  @media only screen and (max-width: 36em) {
    .j-heading-secondary-major {
      font-size: 2rem; } }
  @media only screen and (max-width: 22em) {
    .j-heading-secondary-major {
      font-size: 1.7rem; } }

.j-heading-tertiary-white {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600; }
  @media only screen and (max-width: 36em) {
    .j-heading-tertiary-white {
      font-size: 2rem; } }

.j-heading-quatinary-major {
  font-size: 2.2rem;
  color: #8081b7;
  font-weight: 600; }

.j-heading-quatinary-white {
  font-size: 2rem;
  color: #FFF;
  font-weight: 600; }

.j-heading-quatinary-small-white {
  font-size: 1.6rem;
  color: #FFF;
  font-weight: 600; }

.j-heading-quatinary-black {
  font-size: 2rem;
  color: #000;
  font-weight: 600; }

.j-heading-quatinary-blue {
  font-size: 2rem;
  color: #8081b7;
  font-weight: 600; }

.j-paragraph-1 {
  font-size: 1.48rem; }

.j-paragraph-2 {
  font-size: 1.48rem;
  color: #FFF; }

.j-paragraph-3 {
  font-size: 1.48rem;
  color: #8081b7; }

.j-list {
  font-size: 1.48rem; }

.j-error-message {
  font-size: 1.48rem;
  color: red; }

.j-toast-style {
  font-size: 1.48rem; }

.u-center-text {
  text-align: center !important; }

.u-margin-top-small {
  margin-top: 1.5rem !important; }

.u-margin-top-medium {
  margin-top: 4rem !important; }

.u-margin-top-large {
  margin-top: 8rem !important; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }

.u-margin-bottom-large {
  margin-bottom: 8rem !important; }

.u-margin-left-small {
  padding-left: 1.5rem; }

.u-margin-left-medium {
  padding-left: 4rem; }

.u-margin-left-large {
  padding-left: 8rem; }

.u-format-primary {
  color: #28b485;
  font-weight: 900; }

@media only screen and (max-width: 36em) {
  .j-header__nav-logo img {
    width: 15rem; } }

@media only screen and (max-width: 22em) {
  .j-header__nav-logo img {
    width: 10rem; } }

.j-header__nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.48rem;
  font-weight: 600;
  padding: 1rem 3%;
  background-color: white;
  border-top: 4px solid #8081b7;
  z-index: 10;
  transition: 0.4s ease-in; }
  @media only screen and (max-width: 36em) {
    .j-header__nav {
      height: 60px; } }

.j-header__nav-list {
  display: flex;
  justify-content: center;
  align-items: center; }
  .j-header__nav-list a {
    text-decoration: none;
    color: #8081b7;
    transition: 0.4s ease-in; }

.j-header__link {
  padding: 0.4rem 1rem;
  color: #8081b7; }
  .j-header__link:hover, .j-header__link:focus {
    background-color: #8081b7;
    border-radius: 4px;
    outline: none;
    color: #FFF; }
  @media only screen and (max-width: 62em) {
    .j-header__link {
      display: none; } }

.j-header__link-active {
  color: #8081b7;
  padding: 0.4rem 1rem; }
  .j-header__link-active:hover, .j-header__link-active:focus {
    background-color: #8081b7;
    border-radius: 4px;
    outline: none;
    color: #FFF; }

.j-header__icon {
  line-height: 20px;
  padding: 0.4rem 1rem;
  cursor: pointer;
  color: #8081b7; }
  .j-header__icon:hover {
    background-color: #8081b7;
    border-radius: 4px;
    color: #FFF; }
  .j-header__icon i {
    font-weight: 900; }
  .j-header__icon span {
    font-size: 1.1rem; }

.j-header__menu-btn {
  display: none; }
  @media only screen and (max-width: 62em) {
    .j-header__menu-btn {
      display: block; } }

.j-header__dropdown-wrapper {
  position: relative;
  cursor: pointer; }
  .j-header__dropdown-wrapper:hover > .j-header__dropdown {
    display: block; }

.j-header__dropdown {
  display: none;
  width: 24rem;
  position: absolute;
  top: 2.9rem;
  left: 0rem;
  font-size: 1.4rem;
  list-style-type: none;
  background-color: #0e1820;
  padding: 1.6rem;
  padding-top: 2rem;
  border-radius: 4px; }
  .j-header__dropdown li {
    margin-bottom: 1rem; }
    .j-header__dropdown li a {
      color: #FFF; }
    .j-header__dropdown li a:hover {
      color: #8081b7; }

.j-btn-1 {
  padding: 1rem 2rem;
  color: #FFF;
  background-color: #8081b7;
  font-size: 1.48rem;
  font-weight: 600;
  border-radius: 1rem;
  border: none;
  transition: .5s ease-out; }
  .j-btn-1:hover {
    background-color: #FFF;
    color: #8081b7;
    margin-left: 1.5rem; }
    .j-btn-1:hover a {
      color: #8081b7; }
    .j-btn-1:hover span {
      margin-right: 5px; }
    .j-btn-1:hover i {
      display: inline; }
  .j-btn-1 a {
    display: inline-block;
    color: #FFF; }
  .j-btn-1 i {
    display: none; }

.j-footer {
  background-color: #0e1820;
  padding-top: 6%;
  padding-bottom: 6%;
  color: #FFF;
  font-size: 1.4rem; }
  .j-footer__container {
    padding-left: 5%;
    padding-right: 5%; }
  .j-footer__icons {
    font-size: 2rem; }
    .j-footer__icons a {
      color: #FFF;
      transition: 0.6s ease-in; }
    .j-footer__icons a:not(:last-child) {
      margin-right: 2rem; }
    .j-footer__icons a:hover {
      color: #28b485; }
  .j-footer__contact a {
    color: #28b485;
    border-bottom: 2px solid #28b485;
    transition: .6s ease-out; }
    @media only screen and (max-width: 15em) {
      .j-footer__contact a {
        font-size: 1rem; } }
  .j-footer__contact a:hover {
    color: #28b485;
    border-bottom: none; }
  .j-footer__link {
    color: #bfbfc1;
    margin-right: 2rem;
    transition: .6s ease-out; }
    .j-footer__link:hover {
      color: #bfbfc1;
      border-bottom: 5px solid #bfbfc1; }
  .j-footer__copyright {
    color: rgba(191, 191, 193, 0.5); }
    @media only screen and (max-width: 36em) {
      .j-footer__copyright {
        padding-left: 1rem; } }

.jof-sidebar {
  background-color: #FAF9F6;
  font-size: 1.8rem; }
  .jof-sidebar h2 {
    color: #8081b7; }
  .jof-sidebar__nav {
    margin-top: 2rem; }
  .jof-sidebar__item {
    margin-bottom: 1rem; }
    .jof-sidebar__item a {
      display: inline-block;
      width: 100%;
      color: inherit;
      padding: 1rem 4rem;
      background-color: #FFF;
      border-radius: 10px;
      font-weight: 600;
      transition: .3s ease-in; }
    .jof-sidebar__item a:hover {
      background-color: #bfbfc1;
      box-shadow: 3px 2px 1px 3px rgba(0, 0, 0, 0.3); }
  .jof-sidebar__dropdown-menu {
    width: max-content;
    font-size: 1.48rem;
    padding: 1rem; }
    .jof-sidebar__dropdown-menu li {
      margin-bottom: 1rem; }
    .jof-sidebar__dropdown-menu a {
      padding: 1rem; }

.jof-carousel-1__item {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../public/img/nihilo-11.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (max-width: 62em) {
    .jof-carousel-1__item {
      height: 85vh; } }
  @media only screen and (max-width: 48em) {
    .jof-carousel-1__item {
      height: 70vh; } }
  @media only screen and (max-width: 36em) {
    .jof-carousel-1__item {
      height: 50vh; } }

.jof-carousel-1__item-1 {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../public/img/nihilo-11.jpg); }

.jof-carousel-1__item-2 {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../public/img/nihilo-10.jpg); }

.jof-carousel-1__item-3 {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../public/img/nihilo-12.jpg); }

.jof-carousel-1__item-4 {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../public/img/nihilo-18.jpg); }

.jof-carousel-1__item-5 {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../public/img/nihilo-19.jpg); }

.jof-carousel-1__item-6 {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../public/img/nihilo-14.jpg); }

.jof-carousel-1__header {
  font-weight: 600; }

.jof-carousel-1__paragraph {
  font-size: 1.48rem; }

.jof-carousel-1__caption-indicators {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%) translateY(-70%); }
  @media only screen and (max-width: 36em) {
    .jof-carousel-1__caption-indicators {
      top: 60%;
      transform: translateX(-50%) translateY(-60%);
      width: 96%; } }

.jof-carousel-1__indicators {
  color: #8081b7; }
  .jof-carousel-1__indicators i {
    cursor: pointer;
    margin-left: 5px;
    font-size: 2rem; }
  .jof-carousel-1__indicators i:hover {
    color: #FFF; }

.jof-pagination {
  display: inline-flex; }
  .jof-pagination__page-link {
    width: 3rem;
    height: 3rem;
    color: #000;
    font-size: 1.48rem; }
    .jof-pagination__page-link:hover {
      background-color: #8081b7;
      color: #FFF; }
  .jof-pagination__page-number {
    font-size: 1.48rem;
    margin-left: 1rem; }
    @media only screen and (max-width: 22em) {
      .jof-pagination__page-number {
        display: block; } }

.jof-search-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(128, 129, 183, 0.9);
  width: 100%;
  height: 100vh;
  color: #FFF;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s; }
  .jof-search-popup__form {
    width: 50%;
    height: 6rem;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 62em) {
      .jof-search-popup__form {
        width: 80%; } }
    @media only screen and (max-width: 36em) {
      .jof-search-popup__form {
        width: 95%; } }
    .jof-search-popup__form ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #FFF;
      opacity: 1;
      /* Firefox */ }
    .jof-search-popup__form :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #FFF; }
    .jof-search-popup__form ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #FFF; }
  .jof-search-popup__input {
    width: 80%;
    height: inherit;
    border: none;
    font-size: 2.5rem;
    background-color: inherit;
    padding: 1.3rem;
    color: #FFF !important; }
  .jof-search-popup__input:focus {
    outline: none;
    border: none; }
  .jof-search-popup__button {
    margin: auto;
    background-color: inherit;
    border: none; }
    .jof-search-popup__button a {
      color: #FFF; }
  .jof-search-popup__search, .jof-search-popup__close {
    font-size: 3rem;
    color: #FFF;
    cursor: pointer; }

.show-search-popup {
  visibility: visible;
  opacity: 1; }

.j-home-header {
  height: 120vh;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../public/img/nihilo-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #FFF; }
  @media only screen and (max-width: 75em) {
    .j-home-header {
      height: 100vh; } }
  @media only screen and (max-width: 36em) {
    .j-home-header {
      height: 55vh; } }
  .j-home-header__topmost {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    background-color: rgba(14, 24, 32, 0.7); }
    @media only screen and (max-width: 36em) {
      .j-home-header__topmost {
        display: none; } }
  @media only screen and (max-width: 36em) {
    .j-home-header__nav-logo img {
      width: 15rem; } }
  @media only screen and (max-width: 22em) {
    .j-home-header__nav-logo img {
      width: 10rem; } }
  .j-home-header__nav {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.48rem;
    font-weight: 600;
    padding: 3%;
    z-index: 10;
    transition: 0.7s ease-in; }
    @media only screen and (max-width: 36em) {
      .j-home-header__nav {
        height: 60px; } }
    .j-home-header__nav a {
      text-decoration: none;
      color: #FFF;
      transition: 0.4s ease-in; }
  .j-home-header__nav-list {
    display: flex;
    justify-content: center;
    align-items: center; }
  .j-home-header__link {
    padding: 0.4rem 1rem; }
    .j-home-header__link:hover, .j-home-header__link:focus {
      background-color: #8081b7;
      border-radius: 4px;
      outline: none; }
    @media only screen and (max-width: 62em) {
      .j-home-header__link {
        display: none; } }
  .j-home-header__link-active {
    color: #8081b7;
    padding: 0.4rem 1rem; }
    .j-home-header__link-active:hover, .j-home-header__link-active:focus {
      background-color: #8081b7;
      border-radius: 4px;
      outline: none; }
  .j-home-header__icon {
    line-height: 20px;
    padding: 0.4rem 1rem;
    cursor: pointer; }
    .j-home-header__icon:hover {
      border-radius: 4px;
      background-color: #8081b7; }
    .j-home-header__icon i {
      font-weight: 900; }
    .j-home-header__icon span {
      font-size: 1.1rem; }
  .j-home-header__menu-btn {
    display: none; }
    @media only screen and (max-width: 62em) {
      .j-home-header__menu-btn {
        display: block; } }
  .j-home-header__dropdown-wrapper {
    position: relative;
    cursor: pointer; }
    .j-home-header__dropdown-wrapper:hover > .j-home-header__dropdown {
      display: block; }
  .j-home-header__dropdown {
    display: none;
    width: 24rem;
    position: absolute;
    top: 2.9rem;
    left: 0rem;
    font-size: 1.4rem;
    list-style-type: none;
    background-color: #0e1820;
    padding: 1.6rem;
    padding-top: 2rem;
    border-radius: 4px; }
    .j-home-header__dropdown li {
      margin-bottom: 1rem; }
      .j-home-header__dropdown li a {
        color: #FFF; }
      .j-home-header__dropdown li a:hover {
        color: #8081b7; }
  .j-home-header__container {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .j-home-header__text-container {
    width: 40%;
    margin-left: 10%; }
    @media only screen and (max-width: 75em) {
      .j-home-header__text-container {
        width: 60%;
        margin-left: 5%; } }
    @media only screen and (max-width: 48em) {
      .j-home-header__text-container {
        width: 90%;
        background-color: rgba(128, 129, 183, 0.7);
        padding: 3rem 2rem; } }
    .j-home-header__text-container p {
      width: 80%;
      font-weight: 600;
      font-size: 1.48rem; }
      @media only screen and (max-width: 36em) {
        .j-home-header__text-container p {
          font-size: 1.5rem;
          line-height: 2rem; } }
      @media only screen and (max-width: 15em) {
        .j-home-header__text-container p {
          display: none; } }
  .j-home-header__search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem; }
    @media only screen and (max-width: 48em) {
      .j-home-header__search-container {
        display: none; } }
  .j-home-header__search-box {
    width: 45%;
    background-color: #0e1820;
    padding: 2rem;
    text-align: center;
    border-radius: 8px; }
    @media only screen and (max-width: 62em) {
      .j-home-header__search-box {
        width: 70%; } }
    @media only screen and (max-width: 48em) {
      .j-home-header__search-box {
        width: 100%; } }
    .j-home-header__search-box-link {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: #FFF; }
    .j-home-header__search-box-search {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      cursor: pointer; }
      .j-home-header__search-box-search div:first-child {
        width: 100%;
        height: 4rem; }
      .j-home-header__search-box-search div:not(:first-child) {
        width: 10%;
        height: 4rem;
        margin-left: -4px;
        background-color: #8081b7;
        border-radius: 4px; }
      .j-home-header__search-box-search input {
        width: 100%;
        height: inherit;
        font-size: 1.48rem;
        padding-left: 2rem;
        border-radius: 4px; }
        .j-home-header__search-box-search input:focus {
          outline: none; }
      .j-home-header__search-box-search i {
        font-size: 2rem; }
    .j-home-header__search-box-btn {
      display: flex;
      justify-content: space-between;
      font-size: 1.48rem; }
      @media only screen and (max-width: 36em) {
        .j-home-header__search-box-btn {
          flex-direction: column; } }
      .j-home-header__search-box-btn button:first-child {
        flex: 0 1 68%;
        background-color: #8081b7;
        color: #FFF;
        border-radius: 4px; }
      .j-home-header__search-box-btn button:not(:first-child) {
        flex: 0 1 30%;
        background-color: #0e1820;
        box-sizing: border-box;
        border: 2px solid rgba(255, 255, 255, 0.5);
        color: #FFF;
        border-radius: 4px; }

@media only screen and (max-width: 36em) {
  .j-home-header-main__nav-logo img {
    width: 15rem; } }

@media only screen and (max-width: 22em) {
  .j-home-header-main__nav-logo img {
    width: 10rem; } }

.j-home-header-main__nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  font-size: 1.48rem;
  font-weight: 600;
  padding: 1rem 3%;
  background-color: white;
  border-top: 4px solid #8081b7;
  z-index: 10;
  transition: 0.4s ease-in; }
  @media only screen and (max-width: 36em) {
    .j-home-header-main__nav {
      height: 60px; } }

.j-home-header-main__nav-list {
  display: flex;
  justify-content: center;
  align-items: center; }
  .j-home-header-main__nav-list a {
    text-decoration: none;
    color: #8081b7;
    transition: 0.4s ease-in; }

.j-home-header-main__link {
  padding: 0.4rem 1rem;
  color: #8081b7; }
  .j-home-header-main__link:hover, .j-home-header-main__link:focus {
    background-color: #8081b7;
    border-radius: 4px;
    outline: none;
    color: #FFF; }
  @media only screen and (max-width: 62em) {
    .j-home-header-main__link {
      display: none; } }

.j-home-header-main__link-active {
  color: #8081b7;
  padding: 0.4rem 1rem; }
  .j-home-header-main__link-active:hover, .j-home-header-main__link-active:focus {
    background-color: #8081b7;
    border-radius: 4px;
    outline: none;
    color: #FFF; }

.j-home-header-main__icon {
  line-height: 20px;
  padding: 0.4rem 1rem;
  cursor: pointer;
  color: #8081b7; }
  .j-home-header-main__icon:hover, .j-home-header-main__icon:active {
    border-radius: 4px;
    color: #FFF;
    background-color: #8081b7; }
  .j-home-header-main__icon i {
    font-weight: 900; }
  .j-home-header-main__icon span {
    font-size: 1.1rem; }

.j-home-header-main__menu-btn {
  display: none; }
  @media only screen and (max-width: 62em) {
    .j-home-header-main__menu-btn {
      display: block; } }

.j-home-header-main__dropdown-wrapper {
  position: relative;
  cursor: pointer; }
  .j-home-header-main__dropdown-wrapper:hover > .j-home-header-main__dropdown {
    display: block; }

.j-home-header-main__dropdown {
  display: none;
  width: 24rem;
  position: absolute;
  top: 2.9rem;
  left: 0rem;
  font-size: 1.4rem;
  list-style-type: none;
  background-color: #0e1820;
  padding: 1.6rem;
  padding-top: 2rem;
  border-radius: 4px; }
  .j-home-header-main__dropdown li {
    margin-bottom: 1rem; }
    .j-home-header-main__dropdown li a {
      color: #FFF; }
    .j-home-header-main__dropdown li a:hover {
      color: #8081b7; }

.jof-full-page-spinner {
  height: 100vh;
  background-color: #8081b7;
  position: relative; }
  .jof-full-page-spinner__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%); }

.j-home__sect-1 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20% 10%;
  transition: 1s ease-out; }
  @media only screen and (max-width: 36em) {
    .j-home__sect-1 {
      flex-direction: column;
      justify-content: center;
      padding: 10% 5%; } }
  .j-home__sect-1__div-1-imaginary {
    flex: 0 1 50%;
    opacity: 0;
    visibility: hidden;
    transition: 1s ease-out; }
    @media only screen and (max-width: 36em) {
      .j-home__sect-1__div-1-imaginary {
        flex: 0 1 100%;
        opacity: 1;
        visibility: visible; } }
  .j-home__sect-1__div-1 {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 30%;
    transition: 1s ease-out; }
    @media only screen and (max-width: 36em) {
      .j-home__sect-1__div-1 {
        display: none; } }
    .j-home__sect-1__div-1 h2 {
      height: 0;
      opacity: 0;
      transition-delay: 1.2s; }
  .j-home__sect-1__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    color: #8081b7; }
    @media only screen and (max-width: 36em) {
      .j-home__sect-1__text-sub {
        font-size: 1.8rem; } }
  .j-home__sect-1__text-main {
    margin-bottom: 4rem; }
  .j-home__sect-1__div-2 {
    flex: 0 1 50%; }
    @media only screen and (max-width: 36em) {
      .j-home__sect-1__div-2 {
        flex: 0 1 100%; } }

.j-home .show-div-1 {
  position: fixed;
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  top: 50%; }
  .j-home .show-div-1 h2 {
    height: inherit;
    opacity: 1; }

.j-home .hide-div-1 {
  opacity: 0;
  visibility: hidden;
  top: 0;
  transform: none; }

.j-home__sect-2 {
  padding: 6% 5%;
  background-color: #0e1820;
  color: #FFF; }
  .j-home__sect-2-h2 {
    transition: 0.6s ease-out; }
  .j-home__sect-2-img {
    cursor: pointer;
    transition: 0.6s ease-out; }
  .j-home__sect-2-img:hover {
    transform: scale(1.06); }
  .j-home__sect-2-img:hover ~ .j-home__sect-2-h2 {
    color: #8081b7;
    margin-left: 4rem; }
  .j-home__sect-2-list {
    list-style-type: none; }
    .j-home__sect-2-list li {
      margin-bottom: 0.5rem; }
    .j-home__sect-2-list i {
      color: #8081b7; }

.j-home__sect-3 {
  padding: 6% 5%;
  color: #000; }
  .j-home__sect-3-h2 {
    transition: 0.6s ease-out; }
  .j-home__sect-3-img {
    cursor: pointer;
    transition: 0.6s ease-out; }
  .j-home__sect-3-img:hover {
    transform: scale(1.06); }
  .j-home__sect-3-img:hover ~ .j-home__sect-3-h2 {
    color: #8081b7;
    margin-left: 4rem; }
  .j-home__sect-3-list {
    list-style-type: none; }
    .j-home__sect-3-list li {
      margin-bottom: 0.5rem; }
    .j-home__sect-3-list i {
      color: #8081b7; }

.j-home__sect-4 {
  background-color: #0e1820;
  color: #FFF;
  padding: 6% 5%; }
  .j-home__sect-4-img-1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(../../public/img/nihilo-4.jpg);
    background-size: cover;
    background-position: center;
    height: 80vh;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: 0.4s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center; }
    .j-home__sect-4-img-1:hover {
      transform: skew(0deg, -2deg);
      margin-left: -3rem; }
    @media only screen and (max-width: 48em) {
      .j-home__sect-4-img-1 {
        height: 50vh;
        margin-bottom: 3rem;
        width: 97%; } }
  .j-home__sect-4-img-sm {
    margin-left: 1rem; }
    @media only screen and (max-width: 48em) {
      .j-home__sect-4-img-sm {
        margin-left: 0; } }
  .j-home__sect-4-img-2 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(../../public/img/nihilo-5.jpg);
    background-size: cover;
    background-position: center;
    height: 39vh;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: 0.4s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem; }
    .j-home__sect-4-img-2:hover {
      transform: scale3d(1, 1.05, 1.08);
      margin-right: -3rem; }
    @media only screen and (max-width: 48em) {
      .j-home__sect-4-img-2 {
        height: 50vh;
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 3rem; } }
  .j-home__sect-4-img-3 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(../../public/img/nihilo-6.jpg);
    background-size: cover;
    background-position: center;
    height: 39vh;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: 0.4s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center; }
    .j-home__sect-4-img-3:hover {
      margin-bottom: -3rem; }
    @media only screen and (max-width: 48em) {
      .j-home__sect-4-img-3 {
        height: 50vh;
        margin-left: 0.5%;
        margin-right: 0.5%; } }

.j-home__sect-5 {
  padding: 6% 5%;
  font-size: 1.48rem; }
  .j-home__sect-5-row {
    background-color: #FAF9F6; }
  .j-home__sect-5-card-body {
    margin-left: 2rem; }
    @media only screen and (max-width: 48em) {
      .j-home__sect-5-card-body {
        margin-left: 0; } }
  .j-home__sect-5-card-title {
    color: #8081b7;
    font-weight: 600; }

.jof-student-1-sect-1 {
  margin-top: 5%;
  margin-bottom: 10%; }
  @media only screen and (max-width: 62em) {
    .jof-student-1-sect-1 {
      margin-bottom: 20%; } }
  @media only screen and (max-width: 48em) {
    .jof-student-1-sect-1 {
      margin-bottom: 10%; } }
  .jof-student-1-sect-1__header-main {
    font-style: italic;
    margin-left: 3%; }
    @media only screen and (max-width: 62em) {
      .jof-student-1-sect-1__header-main {
        margin-bottom: 3%; } }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-1__header-main {
        margin-top: 3rem; } }
  .jof-student-1-sect-1__container {
    padding: 6%;
    margin: 3%;
    position: relative;
    background-color: #8081b7;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem; }
    @media only screen and (max-width: 62em) {
      .jof-student-1-sect-1__container {
        margin: 1%; } }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-1__container {
        text-align: center; } }
    @media only screen and (max-width: 22em) {
      .jof-student-1-sect-1__container {
        padding: 3%; } }
  .jof-student-1-sect-1__img-box {
    width: 50%; }
    .jof-student-1-sect-1__img-box img {
      border-radius: 2rem 8rem 2rem 0;
      border: 0.5rem solid #FFF; }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-1__img-box {
        width: 100%;
        margin-bottom: 4rem; } }
  .jof-student-1-sect-1__header-absolute {
    position: absolute;
    top: 20%;
    left: 55%;
    color: #8081b7;
    background-color: #FFF;
    padding: 1rem 3rem;
    font-weight: 600;
    font-size: 2.5rem; }
    .jof-student-1-sect-1__header-absolute:hover {
      transform: scale(1.05);
      text-decoration: underline; }
    @media only screen and (max-width: 62em) {
      .jof-student-1-sect-1__header-absolute {
        top: 50%; } }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-1__header-absolute {
        position: initial;
        display: inline-block;
        margin-bottom: 2rem; } }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-1__header-absolute {
        font-size: 2rem;
        padding: 1rem; } }
  .jof-student-1-sect-1__header-lg {
    display: none;
    color: #FFF;
    font-weight: 600;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-left: 3%; }
    @media only screen and (max-width: 62em) {
      .jof-student-1-sect-1__header-lg {
        display: block; } }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-1__header-lg {
        font-size: 2rem; } }
  .jof-student-1-sect-1__header {
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-left: 3%; }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-1__header {
        display: none; } }
  .jof-student-1-sect-1__text-box {
    position: absolute;
    top: 30%;
    left: 45%;
    width: 50%;
    padding: 2rem;
    background-image: linear-gradient(to bottom right, rgba(128, 129, 183, 0.6), #8081b7, #8081b7, rgba(128, 129, 183, 0.6));
    color: #FFF;
    border-radius: 6rem 2rem 2rem 2rem;
    border-top: 0.6rem solid #FFF;
    border-bottom: 2rem solid #FFF;
    border-right: 0.5rem solid #FFF;
    transition: 0.3s ease-in; }
    @media only screen and (max-width: 62em) {
      .jof-student-1-sect-1__text-box {
        top: 65%;
        left: 1%;
        width: 100%; } }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-1__text-box {
        position: initial; } }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-1__text-box {
        padding: 1.8rem; } }
    .jof-student-1-sect-1__text-box p {
      text-align: justify; }

.jof-student-1-sect-2 {
  margin-bottom: 10%; }
  .jof-student-1-sect-2__header-main {
    text-align: center;
    margin-bottom: 5%; }
  .jof-student-1-sect-2__row-1 {
    margin-bottom: 4rem; }
  .jof-student-1-sect-2__box {
    display: inline-block;
    background-color: #FFF;
    padding: 2rem 4rem;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: 0.4s ease-in-out; }
    .jof-student-1-sect-2__box:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px; }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-2__box {
        width: 70%; } }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-2__box {
        width: 80%; } }
    @media only screen and (max-width: 15em) {
      .jof-student-1-sect-2__box {
        width: 92%; } }
    .jof-student-1-sect-2__box h3 {
      font-weight: 600; }
    .jof-student-1-sect-2__box h5 {
      font-weight: 600; }
    @media only screen and (max-width: 48em) {
      .jof-student-1-sect-2__box-margin-bottom {
        margin-bottom: 3rem; } }

.jof-student-1-sect-3 {
  padding-bottom: 5%; }
  .jof-student-1-sect-3__btn {
    background-color: #8081b7 !important;
    color: #FFF !important;
    font-size: 2rem;
    font-weight: 600; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-3__btn {
        font-size: 1.8rem; } }

.jof-student-1-sect-4 {
  background-color: #000; }
  .jof-student-1-sect-4__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 7%;
    padding-bottom: 7%;
    transition: 1s ease-out; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-4__container {
        flex-direction: column;
        justify-content: center;
        padding: 10% 10%;
        padding-left: 3%; } }
  .jof-student-1-sect-4__div-1 {
    flex: 0 1 30%;
    align-self: flex-start;
    transition: 1s ease-out; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-4__div-1 {
        flex: 0 1 100%;
        width: 100%; } }
  .jof-student-1-sect-4__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    color: #8081b7; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-4__text-sub {
        font-size: 1.8rem; } }
  .jof-student-1-sect-4__text-main {
    margin-bottom: 3rem; }
  .jof-student-1-sect-4__div-2 {
    flex: 0 1 50%;
    margin-top: 5rem; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-4__div-2 {
        flex: 0 1 100%;
        margin-top: 0rem; } }

.jof-student-1-sect-5 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20% 10%;
  transition: 1s ease-out; }
  @media only screen and (max-width: 36em) {
    .jof-student-1-sect-5 {
      flex-direction: column;
      justify-content: center;
      padding: 10% 5%; } }
  .jof-student-1-sect-5__div-1-imaginary {
    flex: 0 1 50%;
    opacity: 0;
    visibility: hidden;
    transition: 1s ease-out; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-5__div-1-imaginary {
        flex: 0 1 100%;
        opacity: 1;
        visibility: visible; } }
  .jof-student-1-sect-5__div-1 {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 30%;
    transition: 1s ease-out; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-5__div-1 {
        display: none; } }
    .jof-student-1-sect-5__div-1 h2 {
      height: 0;
      opacity: 0;
      transition-delay: 1.2s; }
  .jof-student-1-sect-5__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    color: #8081b7; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-5__text-sub {
        font-size: 1.8rem; } }
  .jof-student-1-sect-5__text-main {
    margin-bottom: 4rem; }
  .jof-student-1-sect-5__div-2 {
    flex: 0 1 50%; }
    @media only screen and (max-width: 36em) {
      .jof-student-1-sect-5__div-2 {
        flex: 0 1 100%; } }
    .jof-student-1-sect-5__div-2 h3 {
      color: #8081b7;
      font-weight: 600; }
  .jof-student-1-sect-5 .show-div-1 {
    position: fixed;
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%);
    top: 50%; }
    .jof-student-1-sect-5 .show-div-1 h2 {
      height: inherit;
      opacity: 1; }
  .jof-student-1-sect-5 .hide-div-1 {
    opacity: 0;
    visibility: hidden;
    top: 0;
    transform: none; }

.jof-student-2-sect-1 {
  margin-top: 5%; }
  @media only screen and (max-width: 36em) {
    .jof-student-2-sect-1 {
      margin-top: 7%; } }
  .jof-student-2-sect-1__container {
    display: flex;
    justify-content: center;
    margin-bottom: 6rem; }
    @media only screen and (max-width: 36em) {
      .jof-student-2-sect-1__container {
        margin-bottom: 3.8rem; } }
  .jof-student-2-sect-1__row {
    background-color: #FAF9F6; }
  .jof-student-2-sect-1__card-body {
    margin-left: 2rem; }
    @media only screen and (max-width: 48em) {
      .jof-student-2-sect-1__card-body {
        margin-left: 0; } }
  .jof-student-2-sect-1__card-2-header {
    background-color: #8081b7; }
    .jof-student-2-sect-1__card-2-header span {
      color: #FFF;
      font-weight: 600;
      cursor: pointer; }
  .jof-student-2-sect-1__card-2-body {
    height: 20rem;
    background-color: #FAF9F6;
    background-image: linear-gradient(to bottom right, rgba(128, 129, 183, 0.6), rgba(128, 129, 183, 0.1), rgba(128, 129, 183, 0.1), rgba(128, 129, 183, 0.6)), url(../../public/img/nihilo-22.png);
    background-repeat: no-repeat;
    background-position: center; }
    @media only screen and (max-width: 22em) {
      .jof-student-2-sect-1__card-2-body {
        height: 25rem; } }

.jof-student-2-sect-2 {
  margin-top: 6rem; }
  .jof-student-2-sect-2__header-small {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    color: #8081b7; }
  .jof-student-2-sect-2__list-group {
    background-image: linear-gradient(to bottom right, rgba(128, 129, 183, 0.6), rgba(128, 129, 183, 0.4), rgba(128, 129, 183, 0.1), rgba(128, 129, 183, 0.6));
    font-size: 1.48rem; }

.jof-student-2-sect-3 {
  font-size: 2rem; }
  @media only screen and (max-width: 36em) {
    .jof-student-2-sect-3 {
      font-size: 2.2rem; } }
  .jof-student-2-sect-3__header-main {
    margin-top: 5rem;
    color: #8081b7;
    font-weight: 600; }
  .jof-student-2-sect-3__col {
    position: relative;
    background-color: rgba(128, 129, 183, 0.6);
    margin: 3rem;
    height: 20rem;
    padding: 2rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600; }
  .jof-student-2-sect-3__col-1 {
    color: #FFF; }
  .jof-student-2-sect-3__col-2 {
    background-color: rgba(191, 191, 193, 0.6); }
  .jof-student-2-sect-3__col-3 {
    color: #FFF;
    background-color: rgba(40, 180, 133, 0.6); }
  .jof-student-2-sect-3__col-4 {
    background-color: rgba(255, 255, 0, 0.6); }
  .jof-student-2-sect-3__col-5 {
    color: #FFF;
    background-color: rgba(255, 0, 0, 0.6); }
  .jof-student-2-sect-3__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -10; }

.jof-about-us-sect-1 {
  position: relative;
  margin-top: 7rem;
  height: 60vh;
  background-image: linear-gradient(to bottom, rgba(128, 129, 183, 0.1), rgba(0, 0, 0, 0.2), rgba(128, 129, 183, 0.5)), url(../../public/img/nihilo-26.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 36em) {
    .jof-about-us-sect-1 {
      height: 50vh; } }
  .jof-about-us-sect-1__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 129, 183, 0.7);
    padding: 3rem 2rem; }
    @media only screen and (max-width: 36em) {
      .jof-about-us-sect-1__text {
        width: 90%; } }
    .jof-about-us-sect-1__text p {
      color: #FFF; }

.jof-about-us-sect-4 {
  color: #8081b7; }
  .jof-about-us-sect-4__card {
    background-image: linear-gradient(to bottom right, rgba(128, 129, 183, 0.6), rgba(128, 129, 183, 0.4), rgba(128, 129, 183, 0.1), rgba(128, 129, 183, 0.6)); }

.jof-contact-us-sect-1 {
  position: relative;
  margin-top: 7rem;
  height: 70vh;
  background-image: linear-gradient(to bottom, rgba(128, 129, 183, 0.1), rgba(0, 0, 0, 0.5), rgba(128, 129, 183, 0.5)), url(../../public/img/nihilo-28.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 36em) {
    .jof-contact-us-sect-1 {
      height: 50vh; } }
  .jof-contact-us-sect-1__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 129, 183, 0.7);
    padding: 3rem 2rem; }
    @media only screen and (max-width: 36em) {
      .jof-contact-us-sect-1__text {
        width: 90%; } }
    .jof-contact-us-sect-1__text p {
      color: #FFF; }

.jof-contact-us-sect-2 {
  padding: 5rem 0; }
  @media only screen and (max-width: 36em) {
    .jof-contact-us-sect-2 {
      padding: 2.5rem 0; } }
  .jof-contact-us-sect-2__container {
    padding-left: 5%;
    padding-right: 5%; }
  .jof-contact-us-sect-2__header {
    width: max-content;
    color: #8081b7;
    border-bottom: 6px solid #8081b7;
    margin-bottom: 1rem; }
    .jof-contact-us-sect-2__header h3 {
      margin-bottom: 0; }
  .jof-contact-us-sect-2__border {
    border-bottom: 2px solid #8081b7; }

.jof-contact-us-sect-3__form {
  background-color: #8081b7;
  padding: 4rem; }
  .jof-contact-us-sect-3__form input,
  .jof-contact-us-sect-3__form select {
    height: 5rem !important;
    font-size: 1.48rem; }
  .jof-contact-us-sect-3__form textarea {
    font-size: 1.48rem; }
  .jof-contact-us-sect-3__form label {
    font-size: 1.48rem; }

.jof-contact-us-sect-3__form-header {
  margin-bottom: 1rem; }
  .jof-contact-us-sect-3__form-header h2 {
    font-weight: 600;
    font-size: 2.3rem;
    color: #FFF; }
    @media only screen and (max-width: 36em) {
      .jof-contact-us-sect-3__form-header h2 {
        font-size: 1.8rem; } }

.jof-contact-us-sect-3__form-checkbox {
  height: 2rem !important;
  margin-left: 1rem;
  width: 5rem; }

.jof-contact-us-sect-3__form-error {
  color: #3c1313;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 4px; }

.jof-contact-us-sect-3__form-para {
  color: #FFF; }

.jof-courses-sect-1 {
  position: relative;
  margin-top: 7rem;
  height: 90vh;
  background-image: linear-gradient(to bottom, rgba(128, 129, 183, 0.1), rgba(0, 0, 0, 0.5), rgba(128, 129, 183, 0.5)), url(../../public/img/nihilo-29.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 36em) {
    .jof-courses-sect-1 {
      height: 50vh; } }
  .jof-courses-sect-1__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 129, 183, 0.7);
    padding: 3rem 2rem; }
    @media only screen and (max-width: 36em) {
      .jof-courses-sect-1__text {
        width: 90%; } }
    .jof-courses-sect-1__text p {
      color: #FFF; }

.jof-courses-sect-2 {
  background-color: #8081b7;
  padding: 5rem 0; }
  @media only screen and (max-width: 36em) {
    .jof-courses-sect-2 {
      padding: 2.5rem 0; } }

.jof-courses-sect-3 {
  padding: 5rem 0; }
  @media only screen and (max-width: 36em) {
    .jof-courses-sect-3 {
      padding: 2.5rem 0; } }
  .jof-courses-sect-3__search-form {
    max-width: 28rem; }
  .jof-courses-sect-3__form-control {
    height: 4rem;
    font-size: 1.3rem; }
    .jof-courses-sect-3__form-control:focus {
      border-width: 2px;
      border-color: #8081b7 !important;
      box-shadow: none !important; }
  .jof-courses-sect-3__form-btn {
    border: 0.5px solid black;
    font-size: 1.3rem;
    color: #FFF;
    background-color: #8081b7; }
    .jof-courses-sect-3__form-btn a {
      display: inline-block;
      color: #FFF;
      width: 100%; }
    .jof-courses-sect-3__form-btn a:hover {
      color: #000; }
  .jof-courses-sect-3__table {
    font-size: 1.3rem; }
    @media only screen and (max-width: 36em) {
      .jof-courses-sect-3__table th:nth-child(3),
      .jof-courses-sect-3__table th:nth-child(4),
      .jof-courses-sect-3__table td:nth-child(3),
      .jof-courses-sect-3__table td:nth-child(4) {
        display: none;
        /* Hide the third and forth column */ } }
  .jof-courses-sect-3__thead-row {
    background-color: #8081b7;
    color: #FFF; }
  .jof-courses-sect-3__course-link {
    color: #000;
    font-weight: 600; }
    .jof-courses-sect-3__course-link:hover {
      color: #8081b7; }
  .jof-courses-sect-3__accordion {
    max-width: 28rem !important;
    margin-bottom: 3rem; }
  .jof-courses-sect-3__accordion-btn {
    font-size: 1.6rem;
    font-weight: bold; }
    .jof-courses-sect-3__accordion-btn:focus {
      color: #8081b7 !important;
      background-color: white !important;
      border: none !important; }
  .jof-courses-sect-3__accordion-body-item {
    font-size: 1.48rem; }
    .jof-courses-sect-3__accordion-body-item a {
      display: inline-block;
      color: inherit;
      width: 100%;
      padding: 1rem;
      cursor: pointer; }
    .jof-courses-sect-3__accordion-body-item a:hover {
      background-color: #8081b7;
      color: #FFF;
      border-radius: 5px; }

.jof-other-courses {
  margin-top: 2rem;
  margin-bottom: 4rem; }
  .jof-other-courses h2 {
    text-align: center;
    font-weight: 700; }
  .jof-other-courses__countries {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center; }
    .jof-other-courses__countries div {
      width: 300px;
      height: 200px;
      position: relative;
      border-radius: 10px; }
      @media only screen and (max-width: 36em) {
        .jof-other-courses__countries div {
          width: 270px;
          height: 170px; } }
      .jof-other-courses__countries div a {
        text-decoration: none;
        color: white; }
        .jof-other-courses__countries div a img {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 10px; }
        .jof-other-courses__countries div a div {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center; }
          .jof-other-courses__countries div a div:hover {
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); }
          .jof-other-courses__countries div a div h3 {
            font-weight: 600; }
  .jof-other-courses__unis {
    margin-top: 5rem;
    font-size: 1.48rem; }
    .jof-other-courses__unis h4 {
      font-size: 3rem;
      text-align: center; }
    .jof-other-courses__unis-universities h5 {
      font-size: 1.7rem;
      font-weight: 600; }
    .jof-other-courses__unis-universities-main {
      background-color: #FFF;
      border-radius: 10px;
      padding: 20px 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 35px;
      box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.3);
      margin-bottom: 3rem; }
      .jof-other-courses__unis-universities-main-item {
        display: flex;
        gap: 20px;
        align-items: start; }
        .jof-other-courses__unis-universities-main-item i {
          font-size: 30px;
          color: #8081b7; }
  .jof-other-courses__courses {
    background-color: #FFF;
    border-radius: 10px;
    padding: 20px 12px;
    box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.3);
    margin-bottom: 3rem; }
    .jof-other-courses__courses ul {
      display: flex;
      flex-wrap: wrap;
      gap: 35px; }

.jof-course-next-step {
  width: max-content;
  text-align: center;
  background-color: #8081b7;
  padding: 40px 30px;
  margin: 0 auto 4rem auto;
  font-size: 1.6rem;
  color: white; }
  .jof-course-next-step a {
    color: white;
    font-weight: 700;
    text-decoration: underline; }

.jof-course-sect-1 {
  position: relative;
  margin-top: 7rem;
  height: 90vh;
  background-image: linear-gradient(to bottom, rgba(128, 129, 183, 0.1), rgba(0, 0, 0, 0.5), rgba(128, 129, 183, 0.5)), url(../../public/img/nihilo-30.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 36em) {
    .jof-course-sect-1 {
      height: 50vh; } }
  .jof-course-sect-1__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 129, 183, 0.7);
    padding: 3rem 2rem; }
    @media only screen and (max-width: 36em) {
      .jof-course-sect-1__text {
        width: 90%; } }
    .jof-course-sect-1__text p {
      color: #FFF; }

.jof-course-sect-2 {
  background-color: rgba(128, 129, 183, 0.1);
  border-radius: 8px;
  margin: 0.5rem;
  padding: 5rem 0; }
  @media only screen and (max-width: 36em) {
    .jof-course-sect-2 {
      padding: 2.5rem 0; } }
  .jof-course-sect-2__course-property {
    font-weight: 600; }
  .jof-course-sect-2__course-prop-link {
    color: #8081b7;
    text-decoration: underline; }
  .jof-course-sect-2__video {
    height: 60vh; }
    @media only screen and (max-width: 36em) {
      .jof-course-sect-2__video {
        height: 40vh; } }
  .jof-course-sect-2__img-container img {
    width: 200px;
    height: 184px; }
    @media only screen and (max-width: 22em) {
      .jof-course-sect-2__img-container img {
        width: 150px; } }

.jof-course-sect-3 {
  background-color: rgba(128, 129, 183, 0.1);
  border-radius: 8px;
  margin: 0.5rem;
  padding: 5rem 0; }
  @media only screen and (max-width: 36em) {
    .jof-course-sect-3 {
      padding: 2.5rem 0; } }

.jof-course-enquiry-form {
  background-color: rgba(128, 129, 183, 0.1);
  border-radius: 8px;
  margin: 0.5rem;
  padding: 5rem 0; }
  @media only screen and (max-width: 36em) {
    .jof-course-enquiry-form {
      padding: 2.5rem 0; } }
  .jof-course-enquiry-form__asterik {
    font-size: .6rem;
    color: red; }
  .jof-course-enquiry-form__textarea {
    height: 10rem !important;
    resize: none; }

.jof-admin {
  background-color: #8081b7;
  padding-top: 10rem; }
  .jof-admin__form-textarea {
    height: 15rem !important; }

.jof-error-page {
  height: 100vh;
  position: relative; }
  .jof-error-page__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 1.48rem; }
